import React from 'react';
import PropTypes from 'prop-types';

// import { navigate } from 'gatsby';
import { navigate } from "gatsby-plugin-intl"
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';

import { globalHistory } from "@reach/router"

import LoginRegister from './../LoginRegister'


const PrivateRoute = ({ 
  component: Component, 
  location, // from @reach/router ???
  userAuthenticated, // injected by withDrupalOauthConsumer(PrivateRoute);
  ...rest 
}) => {

  // if (!userAuthenticated) {
  //   // If we’re not logged in, redirect to the home page.
  //   // navigate(`/user/login`)
  //   navigate(`/user/login-register`)
  //   return null;
  // }

  if (
    !userAuthenticated && 
    // location.pathname !== `/user/login-register`
    globalHistory.location.pathname !== `/user/login-register`
  ) {
    // navigate(`/user/login-register`)
    // return null;
    return <LoginRegister />
  }

  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default withDrupalOauthConsumer(PrivateRoute);